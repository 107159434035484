window.app.service('ENV', ['appName', function (appName) {
  class ENV {
    static deviceId;
  }

  Object.defineProperties(ENV, {
    appName: { value: appName },
    deviceId: { value: uuidv4(),  configurable: true }
  });

  app.AM.deviceready.then(() => {
    let uuid = (window.cordova && window.device) ? window.device.uuid : ENV.deviceId;

    Object.defineProperties(ENV, {
      deviceId: { value: uuid }
    });
  });

  return ENV;
}]);
