interface ShemaIndex {
  name?:string,
  keyPath:string|Array<string>,
  type?:'BLOB'|'DATE'|'INTEGER'|'NUMERIC'|'TEXT',
  unique?:boolean
}

interface Shema {
  name:string,
  keyPath:string,
  type?:'BLOB'|'DATE'|'INTEGER'|'NUMERIC'|'TEXT',
  autoIncrement?:boolean,
  unique?:boolean,
  indexes?:Array<ShemaIndex>
}

interface AfterMigration {
  data:Array<string>,
  action:(db, data)=>Promise<any>
}

export class Collection {
  name:string;
  schema:Shema;

  private data_modification:(db:any)=>Promise<any> = async function() {};
  private after_migration:AfterMigration;

  constructor(data:{
    shema:Shema,
    data_modification?:(db:any)=>Promise<any>,
    after_migration?:AfterMigration
  }){
    this.name = data.shema.name;
    this.schema = data.shema;
    this.data_modification = data.data_modification || this.data_modification;
    this.after_migration = data.after_migration || null;
  }
}

const LOCAL_STOREG_VERSIONS = 'db_versions';
const DB_OPTIONS = {mechanisms: ["indexeddb"]};

export class DBSystem {
  private collections:Map<string,Collection[]> = new Map;
  private name:string;

  constructor(name:string){ // singleton
    if (this.constructor[Symbol.for("instance")]) return this.constructor[Symbol.for("instance")];

    this.name = name;
    this.constructor[Symbol.for("instance")] = this;
  }

  insertCollection (collection:Collection):DBSystem {
    if (this.collections.has(collection.name)) {
      this.collections.get(collection.name).push(collection);
    } else {
      this.collections.set(collection.name, [collection]);
    }

    return this;
  }

  get currentVersions ():Map<string,number> {
    let versions:Map<string,number> = new Map;
    for(let [key,val] of this.collections) {
      versions.set(key, val.length - 1);
    }

    return versions;
  }

  get previousVersion ():Map<string,number> {
    // NOTE [mfa] In my browser, the local storage object db_versions was always {} resulting in non-iterable error
    const localStorageVersion = JSON.parse(localStorage.getItem(LOCAL_STOREG_VERSIONS) || '[]');

    if(localStorageVersion.length)
      return new Map(localStorageVersion);
    else
      return new Map([]);
  }

  async getDB () {
    if (this.previousVersion.size) {
      return await this.applyMigrationIfNeed(this.previousVersion,this.currentVersions)
    } else {
      let shema = this.getShemByVersion(this.currentVersions);
      let db = await this.getDBbyShema(shema);
      localStorage.setItem(LOCAL_STOREG_VERSIONS, JSON.stringify(this.currentVersions))
      return db;
    }
  }

  private async applyMigrationIfNeed (previousVersion:Map<string,number>,currentVersions:Map<string,number>) {
    let flag = false;

    do {
      flag = false;

      for( let [key,ind] of previousVersion) {
        if (ind < currentVersions.get(key)) {
          console.log(`apply migration for ${key} from ${ind} to ${ind + 1}`);
          previousVersion.set(key, ind +1);
          flag = true;
        }
      }
    } while (flag);

    let shema = this.getShemByVersion(currentVersions);
    localStorage.setItem(LOCAL_STOREG_VERSIONS, JSON.stringify(currentVersions))
    return await this.getDBbyShema(shema);
  }

  private async getDBbyShema (shema:Shema[]) {
    return await new Promise((res,rej) => {
      const ydn = require('../vendor/ydn.db-is-core-qry')

      let db = new ydn.db.Storage(this.name, {stores: shema}, DB_OPTIONS);
      db.onReady((err:any) => {
        if (err) return rej(err);
        res(db);
      })
    })
  }

  private getShemByVersion (versions:Map<string,number>):Shema[] {
    let list:Shema[] = [];

    for (let [key,ind] of versions) {
      list.push(this.collections.get(key)[ind].schema);
    }

    return list;
  }

}
