window.app.service('ExpiredJobsService', ['AssignmentFactory', function (AssignmentFactory) {
  class ExpiredJobs {
    static expiredJobs: any[] = [];
    constructor() {
    }

    static isExpired(ends) {
      let fourWeeks = 4 * 7 * 24 * 60 * 60 * 1000;
      if (ends !== null) return new Date().getTime() >= (Weeks.getEndOfWeek(ends).getTime() + fourWeeks)
      return false;
    };

    static getExpiredJobs () {
      AssignmentFactory.getOwn()
      .then((jobs) => this.expiredJobs = jobs.filter(item => this.isExpired(item.ends_at) || item.active === false));
    };

    static isJobExpired (appCode) {
      let res = this.expiredJobs.find(item => item.app_code && item.app_code.toLowerCase() === appCode.toLowerCase())
      return !!res;
    };

  }

  return ExpiredJobs;
}]);
