/**
 * @ngdoc Services
 * @name ErrHandlerService
 * @description Allerts for error service
 */
window.app.service('ErrHandlerService', ['$translate', 'ErrFactory',function ($translate, ErrFactory) {
  var self = this;

  self.localDB = function (callback, err) {
    if (err) console.warn(err);

    $translate(['error', 'errors.localDB']).then(function (t) {
      callback({
        title: t['error'],
        desc: t['errors.localDB']
      }, err);
    });
  };

  self.remoteServer = function (callback, err) {
    if (err) console.warn(err);

    $translate(['error', 'errors.remoteServer']).then(function (t) {
      callback({
        title: t['error'],
        desc: t['errors.remoteServer']
      }, err);
    });
  };

  return self;
}]);
