import AppRate from 'cordova-plugin-apprate'

window.app.service('ApplicationRatingService', ['APP_USAGES_BEFORE_RATING', 'appId', function (APP_USAGES_BEFORE_RATING, appId) {
  class ApplicationRating {
    init() {
      let preferences = AppRate.getPreferences();
      preferences.useLanguage = localStorage.getItem('lang')
      preferences.usesUntilPrompt = APP_USAGES_BEFORE_RATING
      preferences.simpleMode = true
      preferences.storeAppURL = {
        android: 'market://details?id=' + appId.android,
        ios: appId.ios
      }
      AppRate.setPreferences(preferences);
      AppRate.promptForRating(false)
    }
  }

  return new ApplicationRating;
}]);
