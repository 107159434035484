window.app.service('AuthService', ['$location', '$injector', '$rootScope', 'API', 'ErrFactory', 'UserFactory', 'AppFactory', 
                         function ( $location,   $injector,   $rootScope,   API,   ErrFactory,   UserFactory,   AppFactory) {
  class Auth extends EventEmitter {
    // [mfa] Forward declarations
    static loadRemotеUser;
    static setAccessToken;
    static subscribeToPushNotifications

    _authorizedUser;
    _id;
    clearAuthData;
    getAuthorizedUser;
    getUserId;
    isSigning;
    loadUser;
    restorePasswordByToken;
    setAuthorizedUser;
    signIn;

    constructor () {
      super();

      this._authorizedUser = null;
      this._id = null;
    }

    get userId () {
      if (this._id) return this._id;

      var id = parseInt(localStorage.getItem('user_id'));
      if ( !isNaN(id) ) {
        this._id = +id;
      }

      return this._id;
    }

    get authorizedUser () {
      return this._authorizedUser;
    }

    get accessToken () {
      if (localStorage.hasOwnProperty('access_token')) return localStorage.getItem('access_token');
      else return null;
    }

    logout() {
      let calls = [];
      if (window.cordova) {
        let PushNotificationServices = $injector.get('PushNotificationServices');
        calls.push(PushNotificationServices.unsubscribeFromTopic(this._authorizedUser.push_notifications_targets.user_email_topic));
        calls.push(PushNotificationServices.unsubscribeFromTopic(this._authorizedUser.push_notifications_targets.user_group_topic));
      }
      return Promise.all(calls)
      .catch(err => {
        console.error(err);
        if (window.cordova && !AppFactory.isNetwork()) {
          document.addEventListener('online', function unsubscribe () {
            let topics = [
              this._authorizedUser.push_notifications_targets.user_email_topic,
              this._authorizedUser.push_notifications_targets.user_group_topic
            ];
            document.removeEventListener('online', unsubscribe);
            let PushNotificationServices = $injector.get('PushNotificationServices');
            Promise.all(topics.map(c => PushNotificationServices.unsubscribeFromTopic(c)));
          });
        }
      })
      .then(() => API.signOutV3())
      .catch((err) => console.error(err))
      .then(() => {
        this.clearAuthData();
        if (!document.body.classList.contains('cutout')) document.body.classList.add('cutout');
        return $location.path('/sign-in');
      });
    }

    loginByEmsToken(emsToken) {
      return Promise.resolve(API.Users.signInByEms({
        user: {
          ems_token: emsToken,
          uuid: (window.device && window.device.uuid) ? window.device.uuid : '',
          manufacturer: (window.device && window.device.manufacturer) ? window.device.manufacturer : '',
          model: (window.device && window.device.model) ? window.device.model : ''
        }
      }).$promise)
      .then((r) => r.access_token)
      .then((amToken) => {
        Auth.setAccessToken(amToken);
        return Auth.loadRemotеUser();
      })
      .then((user) => this.setAuthorizedUser(user));
    }

    async restorePassword (email) {
      return await Promise.resolve(
        API.Users.restorePassword({email}).$promise
      );
    }
  }

  Auth.prototype.signIn = function(user) {
    return Promise.resolve(API.signInV3({ user }))
    .then((token) => localStorage.setItem('refresh_token', token))
    .catch((err) => {
      if (err instanceof ErrFactory) return Promise.reject(err);
      else if (err.data && typeof err.data.errors === 'string') return Promise.reject(new ErrFactory(err.data.errors));
      else return Promise.reject(new ErrFactory('errors.invalidSignInDataUsername'));
    })
    .then(() => Auth.loadRemotеUser())
    .then(user => {
      this.setAuthorizedUser(user);
      if (window.cordova) Auth.subscribeToPushNotifications(user);
    });
  };

  Auth.prototype.loadUser = function () {
    if (!this.userId) return Promise.reject(new ErrFactory('errors.receiveUser'));

    return UserFactory.getLocal(this.userId)
      .catch(() => Auth.loadRemotеUser())
      .catch(() => this.logout());
  };

  Auth.setAccessToken = function (token) {
    localStorage.setItem('access_token', token);
  };

  Auth.loadRemotеUser = function () {
    return Promise.resolve()
    .then(() => { 
      if (AppFactory.isNetwork()) return UserFactory.getMe();
      return Promise.reject(new ErrFactory('errors.noInternetConnection'));
    });
  };

  Auth.subscribeToPushNotifications = function (user) {
    let settings = user && user.push_notifications_targets;
    if (settings) {
      let PushNotificationServices = $injector.get('PushNotificationServices');
      Promise.all([
        PushNotificationServices.subscribeToTopic(settings.user_email_topic),
        PushNotificationServices.subscribeToTopic(settings.user_group_topic)
      ])
      .catch(err => {
        console.error(err);
      });
    }
  }

  Auth.prototype.restorePasswordByToken = function(token, password, password_confirmation, callback) {
    var promise, self;
    self = this;
    promise = API.Users.restorePasswordByToken({
      user: {
        reset_password_token: token,
        password: password,
        password_confirmation: password_confirmation
      }
    }).$promise;
    return promise.then(function() {
      return callback(null);
    }, function() {
      return callback(new ErrFactory('errors.wrongToken'));
    });
  };

  Auth.prototype.isSigning = function() {
    return !!this.getAuthorizedUser();
  };

  Auth.prototype.getAuthorizedUser = function() {
    return this._authorizedUser;
  };

  Auth.prototype.getUserId = function() {
    return this.userId;
  };

  Auth.prototype.clearAuthData = function() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('two_factor_authentication_access_token');
    localStorage.removeItem('user_id');
    $rootScope.fcmToken = null;
    $rootScope.ebs = null;
    this._id = null;
    this._authorizedUser = null;
    this.emitEvent('user.updated');
  };

  Auth.prototype.setAuthorizedUser = function(user) {
    if (!this._authorizedUser) {
      this._authorizedUser = user;

      if (user) {
        localStorage.setItem('user_id', user.id.toFixed(0));
        this._id = (+user.id);
        if (window.FirebasePlugin) window.FirebasePlugin.setCrashlyticsUserId(user.id.toFixed(0));
      }
      this.emitEvent('user.updated');
    }
    return Promise.resolve();
  };

  return new Auth;
}]);
