window.app.service('BackButtonService', function () {
  class Item {
    skipDefault: boolean;
    applyNext: boolean;
    action: Function;
    constructor (options) {
      Object.assign(this,options);
      this.skipDefault = this.skipDefault || false;
      this.applyNext = this.applyNext || false;
      this.action = this.action || new Function;
    }

    applyAction () {
      this.action();
    }
  }

  class BackButton {
    _list: any;
    constructor () {
      this._list = [];
    }

    attch (options = {}) {
      let item = new Item(options);
      this._list.push(item);
      return item;
    }

    detach (item) {
      this._list.splice(this._list.indexOf(item),1);
    }

    apply () {
      let skipDefault = false;

      while (this._list.length) {
        let item = this._list.pop();
        item.applyAction();
        skipDefault = skipDefault || item.skipDefault;

        if (item.applyNext === false) break;
      }

      return skipDefault;
    }
  }

  return new BackButton;
});
